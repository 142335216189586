import React from "react";
import BaseLayout from "../../components/layouts/baseLayout";
import TextJumbotron from "../../components/util/textJumbotron";
import { Container, Row, Col } from "react-bootstrap";
import TwoGirlsPlayingImage from "../../components/images/twoGirlsPlaying";
export default function MaplesPage({ data }) {
  return (
    <BaseLayout pageTitle="Maples Preschool Class">
      <TextJumbotron
        heading="THE MAPLES PROGRAM"
        body="(4-YEAR-OLD, TAUGHT SEPT-JUNE)"
      />
      <Container>
        <Row>
          <Col>
            <p className="lead font-weight-bold text-center">
              The Maples program is for children who are 4 years old by December
              31.
            </p>
            <p>
              The activities within the Maples program are engaging, fun,
              creative (and sometimes messy too) as children develop
              foundational skills in literacy, numeracy and other domains while
              being encouraged to explore their world and follow their
              imagination. The Maples program expands on the basics covered in
              preschool.
            </p>
            <p>
              We believe that kids should be kids and encourage activities where
              they can play, be active, use their imagination, and share ideas.
              Where some children may feel passionate about art, others may
              focus on role playing or building. Teachers adapt to the learning
              needs of the child while creating an inclusive classroom. Parents
              can feel confident that their children are encouraged to play
              freely while meeting the benchmarks of early childhood education.
            </p>
            <p>
              With a focus on school readiness, our active learning environment
              includes intentional teaching experiences and child initiated
              learning.
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="mx-auto pt-3 pb-3 my-auto">
            <TwoGirlsPlayingImage className="rounded h-50" />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <h2 className="text-center pb-3 pt-3">Class Highlights </h2>
          </Col>
          <Col xs={12} lg={4}>
            <h3 className="text-center pb-2">Math & Science</h3>
            <ul>
              <li>
                Size, shapes, opposites, left and right, one-to-one
                correspondence, more and less, big and small
              </li>
              <li>Ordinal numbers, printing, counting</li>
              <li>Following directions</li>
              <li>Addition</li>
              <li>Calendar exercises</li>
              <li>Graphing weather starting in January</li>
              <li>
                Measurement of the children occurs in January, March and June
              </li>
              <li>Theme-related science, e.g.: polar animals, space</li>
              <li>Planting seeds</li>
            </ul>
          </Col>
          <Col xs={12} lg={4}>
            <h3 className="text-center pb-2">Phonics / Literature</h3>
            <ul>
              <li>2 sounds per week</li>
              <li>A different work sheet finished each day</li>
              <li>
                Children start reading simple 3-letter words after 6 months
              </li>
              <li>
                Tricky (sight) words are introduced in the seventh month, for
                ample: the, I, you
              </li>
              <li>Comprehension/vocabulary introduced</li>
              <li>Printing letters, reading</li>
              <li>Children build new words a day (March - June)</li>
              <li>
                Children start to print 3 new words a day from dictation (March
                - June)
              </li>
              <li>
                A vast variety of books and poems are introduced, usually
                theme-related
              </li>
              <li>Social stories/ behaviours</li>
              <li>Comprehension, asking ‘exploring the 5 Ws?’</li>
            </ul>
          </Col>
          <Col xs={12} lg={4}>
            <h3 className="text-center pb-2">Art, Music and Play</h3>
            <ul>
              <li>We start out the day with a welcoming song and routine</li>
              <li>Movement songs</li>
              <li>Theme songs during circle times</li>
              <li>A different planned art activity is done a daily basis</li>
              <li>
                Our emphasis is on creativity, language, and fine motor skills
              </li>
              <li>Art activities incorporate both themes and phonics</li>
              <li>Painting, gluing, cutting, coloring and markers</li>
              <li>Social skills are taught through play</li>
              <li>Activity centres</li>
              <li>Snack time and art are part of play time</li>
              <li>
                Dramatic play, art, and floor and block play is age-appropriate
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </BaseLayout>
  );
}
